<template>

<app-content :loading="is.loading && !is.initialised">

	<app-content-head title="Payouts" subtitle="See past and future payouts to your bank account.">

	</app-content-head>

	<app-content-body :loading="is.loading && is.initialised" :is-empty="!collection.length" placeholder="Sorry, no payouts found.">

		<template v-slot:persist>

			<app-content-summary :fixed="true">

				<app-content-summary-item label="Pending" :value="$org.tracking.billing.payouts.pending | formatMoney" />
				<app-content-summary-item label="Next Payout" :grow="true" :value="nextPayout" />
				<app-content-summary-item label="Payouts Sent" :value="$org.tracking.billing.payouts.sent" />
				<app-content-summary-item label="Total Paid" :value="$org.tracking.billing.payouts.total | formatMoney" />

			</app-content-summary>

		</template>

		<app-table>

			<app-table-head :layout="layout">

				<app-table-column text="Date" />
				<app-table-column text="Convention" />
				<app-table-column text="Amount" />
				<app-table-column text="Status" />

			</app-table-head>

			<app-table-body>

				<app-table-row :no-click="true" :green="item.status === 1" :layout="layout" v-for="item in collection" :key="item.id">

					<app-table-cell-text :text="item.date | localDate('MM/DD/YYYY')" />
					<app-table-cell-text :text="item.convention.name" />
					<app-table-cell-text :text="item.amount | formatMoney" />
					<app-table-cell-text :text="payoutStatus[item.status]" />

				</app-table-row>

			</app-table-body>

			<app-table-foot :pagination="pagination" :total="total" />

		</app-table>

	</app-content-body>

</app-content>

</template>

<script>

import mixCollection from '@/mixin/collection'

export default {

	mixins: [mixCollection],

	data: function() {

		return {
			payoutStatus: {
				1: 'Paid',
				2: 'Pending'
			},
			layout: '120px auto 120px 120px',
			endpoint: 'organisation/billing/payouts',
			live: 'organisation/billing/payouts',
			edit: 'Organisation.Billing.Payouts.Edit'
		}

	},

	computed: {

		nextPayout: function() {

			return false //this.$convention.billing.nextPayout

		}

	}

}

</script>

<style scoped>

</style>